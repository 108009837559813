import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "benWood",
  "categories": ["news"],
  "date": "2019-10-03",
  "path": "/blog/we-are-hiring",
  "summary": "Do you enjoy debugging, killing tech-debt, and refactoring code? We're seeking an experienced Ruby on Rails developer to join our team remotely or on-site at our downtown Vancouver, WA office location.",
  "title": "Sr. Ruby on Rails Developer",
  "image": "./ogp.png",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@benjaminwood"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Do you enjoy debugging, killing tech-debt, and refactoring code?`}</strong>{` We're seeking an experienced `}<strong parentName="p">{`Ruby on Rails developer`}</strong>{` to join our team `}<strong parentName="p">{`remotely or on-site`}</strong>{` at our downtown Vancouver, WA office location.`}</p>
    <p>{`We believe that `}<strong parentName="p">{`happy people create better software.`}</strong>{` Clients deserve an experience that is reliable, understandable, and delivers results while maximizing investment. Developers deserve security, enrichment, and fulfillment—personally as well as professionally.`}</p>
    <p>{`We’re `}<strong parentName="p">{`Hint`}</strong>{` – a growing `}<strong parentName="p">{`team`}</strong>{` of developers who are passionate about creating and maintaining `}<strong parentName="p">{`high-quality software`}</strong>{`. Our mission is to transform how software is made by building a better, more `}<strong parentName="p">{`sustainable`}</strong>{` development process for `}<strong parentName="p">{`everyone`}</strong>{`.`}</p>
    <h3>{`Our Core Values`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Kindness`}</strong>{` Our highest priority is treating people with compassion and respect.`}</li>
      <li parentName="ul"><strong parentName="li">{`Autonomy`}</strong>{`: We are self-driven, proactive, and confident in our abilities.`}</li>
      <li parentName="ul"><strong parentName="li">{`Integrity`}</strong>{`: We do what we say. We do the right thing.`}</li>
      <li parentName="ul"><strong parentName="li">{`Teamwork`}</strong>{`: We succeed as a team. There are no rock stars here.`}</li>
      <li parentName="ul"><strong parentName="li">{`Improvement`}</strong>{`: In our software, process and personal lives.`}</li>
    </ul>
    <h3>{`Requirements`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`5+ years`}</strong>{` of experience working with `}<strong parentName="li">{`Ruby on Rails`}</strong>{`.`}</li>
      <li parentName="ul">{`Advanced ruby `}<strong parentName="li">{`debugging skills`}</strong>{`.`}</li>
      <li parentName="ul">{`No fear of `}<strong parentName="li">{`reading the source of Rails & other dependencies`}</strong>{` to understand how things work.`}</li>
      <li parentName="ul">{`Excellent `}<strong parentName="li">{`communication skills`}</strong>{`, both verbal & written.`}</li>
      <li parentName="ul">{`Value `}<strong parentName="li">{`collaboration`}</strong>{`, `}<strong parentName="li">{`mentorship`}</strong>{`, and `}<strong parentName="li">{`open source`}</strong>{`.`}</li>
      <li parentName="ul">{`Love for `}<strong parentName="li">{`testing`}</strong>{`, `}<strong parentName="li">{`performance`}</strong>{`, and `}<strong parentName="li">{`clean-code`}</strong>{`.`}</li>
      <li parentName="ul">{`Familiarity with `}<strong parentName="li">{`common patterns`}</strong>{` in Rails (and anti-patterns).`}</li>
      <li parentName="ul">{`Know more than enough `}<strong parentName="li">{`Git`}</strong>{` to be dangerous.`}</li>
      <li parentName="ul">{`Experience with `}<strong parentName="li">{`Docker`}</strong>{` a bonus, but not required.`}</li>
    </ul>
    <h3>{`Responsibilities`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Lead`}</strong>{` client projects start to finish.`}</li>
      <li parentName="ul"><strong parentName="li">{`Communicate`}</strong>{` with clients, manage expectations, deliver on-time.`}</li>
      <li parentName="ul"><strong parentName="li">{`Invest`}</strong>{` in team members.`}</li>
      <li parentName="ul"><strong parentName="li">{`Contribute`}</strong>{` to Hint blog & open source projects.`}</li>
      <li parentName="ul"><strong parentName="li">{`Extend`}</strong>{` internal knowledge base, systems, and tooling.`}</li>
      <li parentName="ul"><strong parentName="li">{`Represent`}</strong>{` Hint well, stay involved in the Ruby/Rails community.`}</li>
    </ul>
    <h3>{`Compensation & Benefits`}</h3>
    <ul>
      <li parentName="ul">{`$100k+ `}<strong parentName="li">{`salary`}</strong>{` depending on qualifications`}</li>
      <li parentName="ul"><strong parentName="li">{`Medical insurance`}</strong>{` (Hint pays `}<strong parentName="li">{`100% employee`}</strong>{` and 30% dependents)`}</li>
      <li parentName="ul"><strong parentName="li">{`401k`}</strong>{` (Hint matches employee contribution up to 4% of salary)`}</li>
      <li parentName="ul"><strong parentName="li">{`Paid vacation`}</strong>{` (10 days first year, 15 days second year, 20 days third year)`}</li>
      <li parentName="ul">{`Six `}<strong parentName="li">{`paid holidays including a minimum of 5 days at Christmas`}</strong></li>
      <li parentName="ul">{`Client work Mon-Thursday, `}<strong parentName="li">{`Friday reserved`}</strong>{` for internal Hint projects, open source contributions, and continued learning.`}</li>
    </ul>
    <p>{`Sound good? We think so! We'd love to have you join the team. Submit your resume to `}{`[jobs@hint.io]`}{`(mailto: `}<a parentName="p" {...{
        "href": "mailto:jobs@hint.io"
      }}>{`jobs@hint.io`}</a>{`) and tell us about yourself.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      